import React from 'react';
// import { withRouter, Link } from "react-router-dom"

export default function productitems(software) {
    var software_info = require("./software_details.json")
    const details = software_info[software]

    const img = details["img"]
    const description = details["description"]
    // const overview = details["overview"]
    const name = details["name"]

    if(img){
        var display_img = <img className="software_img_body" src={process.env.PUBLIC_URL + img} alt={name} />
    }

    var bullet_list

    var content = () => { return (description.map((paragraph) => {
        if (paragraph[0] === "$") {
            bullet_list = paragraph.substring(1).split("|");
            return <ul className="software_text">{bullet_list.map((bullet_point) => <li>{bullet_point}</li>)}</ul>
        }
        else if (paragraph[0] === "/o") {
            const internal_paragraph = (paragraph) => <p className="software_text">{paragraph}</p>

            bullet_list = paragraph
            return (<ol className="software_text">{bullet_list.map((bullet_point) => { 
                if (bullet_point ==="/o"){
                    return <div></div>
                }
                return (
                <li style={{paddingTop: "2%"}}><b>{bullet_point[0]}</b>{internal_paragraph(bullet_point[1])}</li>)
            })}
            </ol>)
        }
        else if (paragraph[0] === "@") {
            bullet_list = paragraph.substring(1).split("|");
            return <ul className="software_text" style={{ "list-style-type": "none", "padding-top":"0" }}>{bullet_list.map((bullet_point) => <li>{bullet_point}</li>)}</ul>
        }
        else if(paragraph[0] === "/" && paragraph[1] === "b"){
            return <p className="software_text"><b>{paragraph.slice(2)}</b></p>
        }
        else {
            return <p className="software_text">{paragraph}</p>
        }
    }))}

    var wrapped_content
    if(name === "SGCapture" || name === "Geomagic" || name ==="Polyworks"){
        wrapped_content = <div className="vert-align-text">{content()}</div>
    }
    else{
        wrapped_content = content()
    }

    return <div className="software_details">
        {display_img}

        <div className="software_details_text">{wrapped_content}</div>
    </div>


}