import React from 'react';
// import logo from './logo.svg';
// import './App.css';
import Navibar from '../../components/navibar';
import Carousel from './components/carousel';
import Expo from './components/expo';
import Header from '../../components/header';
import Footer from '../../components/footer';

function home() {
  return (
    <div className="home">
      <Header></Header>
      <Navibar></Navibar>
      <Carousel></Carousel>
      <Expo></Expo>
      <Footer></Footer>


    </div>
  );
}

export default home;