import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Map from './map';
import ProductBanner from "../products/components/product_banner"
import banner from './img/contactus.png'
import bannermobile from './img/contactus-mobile.png'

export default class contactus extends Component {
    
    render() {

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner("Contact Us",banner,bannermobile)}
                <Map></Map>
                <Footer></Footer>
            </div >

        )
    }
}
