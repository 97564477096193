import React, { Component } from 'react';
import Navibar from '../../../components/navibar';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Softwaredetailsbody from './software_details_body';
import ProductBanner from "../../products/components/product_banner"
import { withRouter, Route, Redirect } from "react-router-dom"


export default withRouter(class SoftwareItem extends Component {
    render() {
        const software_list = require('./software_details.json')

        var current_url_tail = this.props.match.url
        var software = current_url_tail.split("/")[3]
        // var category_key = current_url_tail.slice(22).split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")

        if (software_list[software] !== undefined) {
            return (
                <div className="home">
                    <Header></Header>
                    <Navibar></Navibar>
                    {ProductBanner(software_list[software].name)}
                    {Softwaredetailsbody(software)}
                    <Footer></Footer>


                </div>
            )
        }
        else {
            return <Route render={() => <Redirect to={"/error"} />} />
        }

    }
}
)