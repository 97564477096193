import React, { Component } from 'react';
import Navibar from '../../../components/navibar';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Softwaredetails from './software_overview';
import ProductBanner from "../../products/components/product_banner"
import { withRouter } from "react-router-dom"

// import ProductCarousel from './product_carousel'

export default withRouter(class SoftwareItem extends Component {
    render() {
        const software_list = require('./software_item.json')

        var current_url_tail = this.props.match.url
        var category_key = current_url_tail.slice(22).split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")


        if (software_list[category_key] !== undefined) {
            return (
                <div className="home">
                    <Header></Header>
                    <Navibar></Navibar>
                    {ProductBanner(category_key)}
                    {Softwaredetails(category_key,current_url_tail)}
                    <Footer></Footer>


                </div>
            )
        }
        else {
            //product not found here
        }

    }
}
)