import React, { Component } from 'react';



export default class Map extends Component {

    render() {
        return (
            <div className="contactus">
                <div className="contact-details">
                    <div>
                        <h3>Phone</h3>
                        <p className="contact-info">Contemporary problems do not always require the most complex solutions. Most of the time, a simple call may be the answer to your queries and issues. Just give us a call!</p>
                        <p className="contact-info-d"><strong>P:</strong> (+61) 423 872 638</p>
                        <h3>Email</h3>
                        <p className="contact-info">If it is outside of standard working hours, you can email us instead and we will get back to you!</p>
                        <p className="contact-info-d"><strong>E:</strong> timlohhk@visionmeasuringoceania.com.au</p>
                    </div>
                </div>
                <div className="map">
                    <h3>Address</h3>
                    <p className="contact-info">Unit 3/9 Nicole Close,<br /> Bayswater North,<br /> VIC 3153</p>
                    <iframe title="map" width="100%" height="700vw" frameborder="0" style={{ "border": "0", "padding-top": "1.5vw" }} src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ-Q7ib-Q61moRo5O5_QD75D0&key=AIzaSyA2glRfA6VVcFDbnYw0ofKZd-QKuMjsrvg" allowfullscreen></iframe>
                </div>

                
                
                

            </div>
        );
    }
}
