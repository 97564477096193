import React, { Component } from 'react'
import { Carousel } from "react-bootstrap"
import img_1 from "../../../img/carousel/1.jpg"
import img_2 from "../../../img/carousel/2.jpg"
import img_1_mobile from "../../../img/carousel/carMobile1.png"
import img_2_mobile from "../../../img/carousel/carMobile2.png"
// import img_3 from "../img/carousel/3.jpg"
class carousel extends Component {
    render() {
        return (
            <div>
            <Carousel id="carousel-normal" interval = "5000">
                <Carousel.Item>
                    <img className="d-block w-100"  src={img_1}  alt="First slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_left'>
                        <h3 className="carousel_text_white">Vision Measuring Oceania</h3>
                        <p className="carousel_subtext_bg_colour">Developing cutting edge measuring solutions, across the Australian and New Zealand region supporting both local and foreign manufacturing. <br></br></p>
                        
                    </Carousel.Caption>

                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block w-100"  src={img_2}  alt="Second slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_right'>
                        <h3 className="carousel_text_white">Touch, a Complement to Sight</h3>
                        <p className="carousel_subtext_bg_colour">With unparallel optical measuring tools, there are occasionally hidden surfaces which are obscured from sight. To compensate, we implement the finest, precisioned touch and feather probes.</p>
                    </Carousel.Caption>
                </Carousel.Item>



                {/* <Carousel.Item>
                    <img className="d-block w-100"  src={img_3}  alt="Third slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_right'>
                        <h3 className="carousel_text_white">Something here</h3>
                        <p className="carousel_subtext_bg_colour">Something there.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                 */}

            </Carousel>

            <Carousel id="carousel-mobile" interval = "5000">
                <Carousel.Item>
                    <img className="d-block w-100"  src={img_1_mobile}  alt="First slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_left'>
                        <h3 className="carousel_text_white">Vision Measuring Oceania</h3>
                        <p className="carousel_subtext_bg_colour">Developing cutting edge measuring solutions, across the Oceanic region supporting both local and foreign manufacturing. <br></br></p>
                        
                    </Carousel.Caption>

                </Carousel.Item>

                <Carousel.Item>
                    <img className="d-block w-100"  src={img_2_mobile}  alt="Second slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_right'>
                        <h3 className="carousel_text_white">Touch, a Complement to Sight</h3>
                        <p className="carousel_subtext_bg_colour">With unparallel optical measuring tools, there are occasionally hidden surfaces which are obscured from sight. To compensate, we implement the finest, precisioned touch and feather probes.</p>
                    </Carousel.Caption>
                </Carousel.Item>



                {/* <Carousel.Item>
                    <img className="d-block w-100"  src={img_3}  alt="Third slide" />
                    <Carousel.Caption bsPrefix = 'carouselcaption_right'>
                        <h3 className="carousel_text_white">Something here</h3>
                        <p className="carousel_subtext_bg_colour">Something there.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                 */}

            </Carousel>
            </div>
        )
    }

    // 1135
}

export default carousel