import React, { Component } from 'react'
import logo from '../img/Logo/logo_header.png'
import {Link} from "react-router-dom"
// #323335
class Example extends Component {
    render() {
        return (
            <div style={{"background-color":"whitesmoke"}}>
                <Link to="/"><img className="header_brand" src={logo} alt="VMA-brand"></img></Link>
                <div></div>
            </div>
        )
    }
}

export default Example