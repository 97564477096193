import React from 'react';
import Navibar from '../../../components/navibar';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import Accessorydetails from './accessory_details';
import ProductBanner from "../../products/components/product_banner"


export default function productitems(match) {

    var banner_title
    var all_products = require('./accessory_item.json')

    const current_url_tail = match.location.pathname.slice(22)

    try {
        if ((current_url_tail.includes("/") && all_products[current_url_tail.split("/")[0]] !== undefined) || all_products[current_url_tail] !== undefined) {

            if (match.location.pathname.split("/").length >= 2) {
                banner_title = match.location.pathname.split("/").splice(2).map(word => word.split("-").join(" ")).join(" ")
                if (banner_title === "touchprobe"){
                    banner_title = "Touch Probe"
                }
                banner_title += " Range"
            }
            else {
                banner_title = match.location.pathname.split("/").pop().split("-").join(" ") + " Range"
            }
        }
        else{
            banner_title = "Product Cannot Be Found"
        }
    }
    catch(e){
        banner_title = "Error"
    }

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                { ProductBanner(banner_title)}
                <Accessorydetails></Accessorydetails>
                <Footer></Footer>
            </div >

        )
    }