import React, { Component } from 'react'
import { Link } from "react-router-dom"
// import logo from '../img/Logo/logo_header.png'

class Footer extends Component {
    render() {
        return (
            <div style={{ width: "100%", height: "auto", "background-color": "#1b1c1d" }}>
                <div className="footer_grid">
                    <div>
                        <h4 className="footer_label">Products</h4>
                        <ul style={{ "list-style-type": "none", "text-align": "left", padding: "0" }}>
                            <Link className="footer_links" as={Link} to="/metrology+systems/smartscope"><li className="footer_links">Smartscope</li></Link>
                            <Link className="footer_links" as={Link} to="/metrology+systems/snap"><li className="footer_links">SNAP</li></Link>
                            <Link className="footer_links" as={Link} to="/metrology+systems/shapegrabber"><li className="footer_links">ShapeGrabber</li></Link>
                            <Link className="footer_links" as={Link} to="/metrology+systems/fusion"><li className="footer_links">Fusion</li></Link>
                            <Link className="footer_links" as={Link} to="/metrology+systems/flexgauge"><li className="footer_links">FlexGuage</li></Link>

                        </ul>
                    </div>

                    <div>
                        <h4 className="footer_label">Links</h4>
                        <ul style={{ "list-style-type": "none", "text-align": "left", padding: "0" }}>
                            <Link className="footer_links" as={Link} to="/solutions"><li className="footer_links">Solutions</li></Link>
                            <Link className="footer_links" as={Link} to="/metrology+systems"><li className="footer_links">Products</li></Link>
                            <Link className="footer_links" as={Link} to="/software+accessories"><li className="footer_links">Services</li></Link>
                            <Link className="footer_links" as={Link} to="/services"><li className="footer_links">{"Software & Accessories"}</li></Link>
                            <Link className="footer_links" as={Link} to="/contactus"><li className="footer_links">Contact Us</li></Link>
                        </ul>
                    </div>





                    <div>
                        <h4 className="footer_label">International</h4>
                        <ul style={{ "list-style-type": "none", "text-align": "left", padding: "0" }}>
                            <li className="footer_links"><a href="https://www.ogpnet.com/">OGP</a></li>
                            <li className="footer_links"><a href="https://www.smartscope.com.sg/">Smartscope SG</a></li>

                        </ul>
                    </div>

                    <div>
                        <h4 className="footer_label">Office</h4>
                        <p className="footer_links">3/9 Nicole Cl, Bayswater North VIC 3153</p>

                        <h4 className="footer_label">Phone</h4>
                        <p className="footer_links">+61 3 423872638</p>


                        <h4 className="footer_label">Email</h4>
                        <p className="footer_links">thk.loh@optusnet.com.au</p>

                    </div>
                </div>



            </div>
        )
    }
}
export default Footer
