import React from 'react';
import { Link } from "react-router-dom"
export default function SoftwareItem(category_key, current_url_tail) {

        const software_json = require('./software_item.json')
        function softwaretabcontent(software_json, category_key, current_url_tail) {

                var headers = Object.keys(software_json[category_key])


                //create items with image and name
                const grid = (category) => headers.map((software) =>
                        <Link className="prod_item" as={Link} to={current_url_tail + "/" + software}>
                                <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/" + category.split(" ")[0][0].toLowerCase() + category.split(" ")[0].substring(1) + "/" + software + ".png"} alt={software}></img>
                                <div className="prod_card">
                                        <div className="prod_header"><b>{software_json[category][software].name}</b></div>
                                        <p className="prod_text">{software_json[category][software].description}</p>
                                        <p className="prod_learn">{"Learn More >"}</p>
                                </div>

                        </Link>
                )

                var content = grid(category_key)

                return content
        }
        return (
                <div className="software_items">{softwaretabcontent(software_json, category_key, current_url_tail)}</div>
        )
}