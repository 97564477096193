import React, { Component } from 'react'
// import Softwarejson from "./software.json"
import { featherprobe, touchprobe, drslaser, rotaries } from "./img/accessory_img_list"
import { Link, withRouter } from "react-router-dom"

export default withRouter(class ProductList extends Component {
    render() {
        var parent = "software+accessories"
        return (
            <div style={{ textAlign: "center" }}>
                <Link className="prod_item" as={Link} to={"/" + parent + "/touchprobe"}>
                    <img className="prod_img" src={touchprobe} alt="touchprobe"></img>
                    <div className="prod_card">
                        <div className="prod_header"><b>Touch Probes</b></div>
                        <p className="prod_text">OGP SmartScope multisensor measurement systems combine optical, laser and tactile sensors, letting you measure parts more completely, with lower uncertainty and in less time</p>
                        <p className="prod_learn"><b>{"Learn More >"}</b></p>
                    </div>
                </Link>
                <Link className="prod_item" as={Link} to={"/" + parent + "/microprobe"}>
                    <img className="prod_img" src={featherprobe} alt="featherprobe"></img>
                    <div className="prod_card">
                        <div className="prod_header"><b>Micro Probes</b></div>
                        <p className="prod_text">Large Field-of-View 3D Multisensor Systems</p>
                        <p className="prod_learn"><b>{"Learn More >"}</b></p>
                    </div>
                </Link>
                <Link className="prod_item" to={"/" + parent + "/laser"}>
                    <img className="prod_img" src={drslaser} alt="drs-laser"></img>
                    <div className="prod_card">
                        <div className="prod_header"><b>Lasers</b></div>
                        <p className="prod_text">A new generation of large format multisensor CMMs</p>
                        <p className="prod_learn"><b>{"Learn More >"}</b></p>
                    </div>
                </Link>
                <Link className="prod_item" as={Link} to={"/" + parent + "/rotary"}>
                    <img className="prod_img" src={rotaries} alt="rotaties"></img>
                    <div className="prod_card">
                        <div className="prod_header"><b>Rotary</b></div>
                        <p className="prod_text">Sets the standard for fully automatic 3-axis measurement performance and rugged granite construction, precision motorized stages and flexible zoom optics</p>
                        <p className="prod_learn"><b>{"Learn More >"}</b></p>
                    </div>
                </Link>
            </div>
        )
    }

})

