import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import Navibar from './components/navibar';
// import Carousel from './components/carousel';
// import Expo from './components/expo';
// import Header from './components/header';
// import Footer from './components/footer';
import Home from './pages/home/home'
import Error from './pages/home/error'
import Products from './pages/products/products'
import ProductsItem from './pages/products/smartscope_series'
import ProductsItems from './pages/products/product_series'
import Contact from './pages/contactus/contact'
import Solutions from './pages/solutions/solutions'
import SolutionDetails from './pages/solutions/solution_details'
import Services from './pages/services/services'
import SoftwareAccessory from './pages/softwareaccessory/softwareaccessory'
import SoftwareItem from './pages/softwareaccessory/software/software_item'
import Softwaredetails from './pages/softwareaccessory/software/software_details'
import AccessoryItem from './pages/softwareaccessory/accessories/accessory_item'
import ServiceDetails from './pages/services/services_details'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

function VMA() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/metrology+systems" exact component={Products} />
          <Route path="/metrology+systems/smartscope" exact component={ProductsItem} />
          <Route path="/metrology+systems/:id" component={ProductsItems} />
          <Route path="/contactus" exact component={Contact} />
          <Route path="/solutions" exact component={Solutions} />
          <Route path="/solutions/:id"  component={SolutionDetails} />
          <Route path="/services" exact component={Services} />
          <Route path="/software+accessories" exact component={SoftwareAccessory} />
          <Route path="/software+accessories/touchprobe" exact component={AccessoryItem} />
          <Route path="/software+accessories/microprobe" exact component={AccessoryItem} />
          <Route path="/software+accessories/laser" exact component={AccessoryItem} />
          <Route path="/software+accessories/rotary" exact component={AccessoryItem} />

          <Route path="/software+accessories/metrology+software" exact component={SoftwareItem} />
          <Route path="/software+accessories/metrology+software/:id" component={Softwaredetails} />

          <Route path="/software+accessories/evolve+software+suite" exact component={SoftwareItem} />
          <Route path="/software+accessories/evolve+software+suite/:id" component={Softwaredetails} />

          <Route path="/software+accessories/fitting+software" exact component={SoftwareItem} />
          <Route path="/software+accessories/fitting+software/:id" component={Softwaredetails} />

          <Route path="/software+accessories/optical+comparator+software" exact component={SoftwareItem} />
          <Route path="/software+accessories/optical+comparator+software/:id" component={Softwaredetails} />

          <Route path="/software+accessories/shapegrabber+software" exact component={SoftwareItem} />
          <Route path="/software+accessories/shapegrabber+software/:id" component={Softwaredetails} />

          <Route path="/software+accessories/user+interface+software" exact component={SoftwareItem} />
          <Route path="/software+accessories/user+interface+software/:id"  component={Softwaredetails} />

          <Route path="/services/parts+upgrades" exact component={ServiceDetails} />
          <Route path="/services/training" exact component={ServiceDetails} />
          <Route path="/services/field+service" exact component={ServiceDetails} />

          <Route path="/:id"component={Error} />
          {/* <Route path="/software+accessories/:id" component={AccessoryItem} /> */}
        </Switch>
      </div>
    </Router>
  );
}

export default VMA;
