import React, { Component } from 'react'
import { smartscope250, fusion400, flexpoint1220, sparkmvp200300, sprintmvp200300, starlite300, snap100, shapegrabberai620, cobra2d, turnchecksilo6, flexgauge, cvisionbenchtop, cfocus } from "../series_img/img_product_list"
import { Link } from "react-router-dom"

class ProductList extends Component {
    render() {
        function product_tab(evt, familyName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(familyName).style.display = "block";
            evt.currentTarget.className += " active";

            document.getElementById("selectBox").value = evt.currentTarget.innerHTML
        }

        function changeFunc() {
            var selectBox = document.getElementById("selectBox");
            var selectedValue = selectBox.options[selectBox.selectedIndex].value;

            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(selectedValue).style.display = "block";
            var tab = document.getElementById(selectedValue + " tab")
            tab.className += " active"
        }

        var parent = "metrology+systems"
        return (
            <div className="productline">
                <div className="tab">
                    <button className="tablinks active" id="Multisensory Systems tab" onClick={(e) => product_tab(e, "Multisensory Systems")} >Multisensory Systems</button>
                    <button className="tablinks" id="Optics Systems tab" onClick={(e) => product_tab(e, "Optics Systems")}>Optics Systems</button>
                    <button className="tablinks" id="Laser Scanning Systems tab" onClick={(e) => product_tab(e, "Laser Scanning Systems")}>Laser Scanning Systems</button>
                    <button className="tablinks" id="Shaft Measuring Systems tab" onClick={(e) => product_tab(e, "Shaft Measuring Systems")}>Shaft Measuring Systems</button>
                    <button className="tablinks" id="Optics Comparators tab" onClick={(e) => product_tab(e, "Optics Comparators")}>Optics Comparators</button>
                    <button className="tablinks" id="Flexible Gaging Systems tab" onClick={(e) => product_tab(e, "Flexible Gaging Systems")}>Flexible Gaging Systems</button>


                </div>

                <div className="product_combobox">
                    <select id="selectBox" className="form-control" onChange={() => changeFunc()}>
                        <option value="Multisensory Systems" className="tablinks active">Multisensory Systems</option>
                        <option value="Optics Systems" className="tablinks">Optics Systems</option>
                        <option value="Laser Scanning Systems" className="tablinks">Laser Scanning Systems</option>
                        <option value="Shaft Measuring Systems" className="tablinks">Shaft Measuring Systems</option>
                        <option value="Optics Comparators" className="tablinks">Optics Comparators</option>
                        <option value="Flexible Gaging Systems" className="tablinks">Flexible Gaging Systems</option>
                    </select>
                </div>


                {/* Multisensory tab */}

                <div className="producttabcontentholder">
                    <div id="Multisensory Systems" className="tabcontent" style={{ display: "block" }}>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/smartscope"}>
                            <img className="sys_prod_img" src={smartscope250} alt="smartscope250"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>SmartScope Series</b></div>
                                <p className="sys_prod_text">OGP SmartScope multisensor measurement systems combine optical, laser and tactile sensors, letting you measure parts more completely, with lower uncertainty and in less time</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/fusion"}>
                            <img className="sys_prod_img" src={fusion400} alt="fusion400"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Fusion Series</b></div>
                                <p className="sys_prod_text">Large Field-of-View 3D Multisensor Systems</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/flexpoint"}>
                            <img className="sys_prod_img" src={flexpoint1220} alt="flexpoint1220"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>FlexPoint Series</b></div>
                                <p className="sys_prod_text">A new generation of large format multisensor CMMs</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/sprintmvp"}>
                            <img className="sys_prod_img" src={sprintmvp200300} alt="sprintmvp200300"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>SprintMVP Series</b></div>
                                <p className="sys_prod_text">Sets the standard for fully automatic 3-axis measurement performance and rugged granite construction, precision motorized stages and flexible zoom optics</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/sparkmvp"}>
                            <img className="sys_prod_img" src={sparkmvp200300} alt="sparkmvp200300"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>SparkMVP Series</b></div>
                                <p className="sys_prod_text">Programmable multisensor, fixed lens automatic video measurement systems</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>

                    {/* optic system tab */}

                    <div id="Optics Systems" className="tabcontent">
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/starlite"}>
                            <img className="sys_prod_img" src={starlite300} alt="starlite300"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>StarLite Series</b></div>
                                <p className="sys_prod_text">A compact, self-contained and programmable semi-automatic video measurement system</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/snap"}>
                            <img className="sys_prod_img" src={snap100} alt="snap100"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>SNAP Series</b></div>
                                <p className="sys_prod_text">Make complex measurements easy and accurate – it's as simple as placing apart on the stage and pressing go</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>

                    {/* Laser system tab */}

                    <div id="Laser Scanning Systems" className="tabcontent">
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/shapegrabber"}>
                            <img className="sys_prod_img" src={shapegrabberai620} alt="shapegrabberai620"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Shapegrabber Series</b></div>
                                <p className="sys_prod_text">Automated 3D Scanning Systems</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/cobra"}>
                            <img className="sys_prod_img" src={cobra2d} alt="cobra2d"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Cobra Series</b></div>
                                <p className="sys_prod_text">Non-Contact Laser Profiling Systems</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>

                    {/* Optic Comparators tab */}
                    <div id="Optics Comparators" className="tabcontent">
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/opticscomparatorb"}>
                            <img className="sys_prod_img" src={cvisionbenchtop} alt="cvisionbenchtop"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Optic Comparator Benchtop Model Series</b></div>
                                <p className="sys_prod_text"> Video Contour Projectors combine the speed and accuracy of a video measurement system with the rugged capacity of an optical comparator</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/opticscomparatorf"}>
                            <img className="sys_prod_img" src={cfocus} alt="focus"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Optic Comparator Floor Model Series</b></div>
                                <p className="sys_prod_text">OGP Contour Projectors combine cutting-edge optics, lighting, and automation technologies for enhanced productivity and profitability</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>

                    {/* Flexible Gaging systems */}

                    <div id="Flexible Gaging Systems" className="tabcontent">
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/flexgauge"}>
                            <img className="sys_prod_img" src={flexgauge} alt="flexgauge"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>Itaca FlexGauge Series</b></div>
                                <p className="sys_prod_text">Flexible measuring systems and software for applications requiring tactile probing</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>

                    {/* Shaft Measuring Systems */}

                    <div id="Shaft Measuring Systems" className="tabcontent">
                        <Link className="sys_prod_item" as={Link} to={"/" + parent + "/turncheck"}>
                            <img className="sys_prod_img" src={turnchecksilo6} alt="turnchecksilo"></img>
                            <div className="sys_prod_card">
                                <div className="sys_prod_header"><b>TurnCheck Series</b></div>
                                <p className="sys_prod_text">Fast, easy way to measure your shafts, cylinders and other turned, ground, or extruded parts</p>
                                <p className="sys_prod_learn"><b>{"Learn More >"}</b></p>
                            </div>
                        </Link>
                    </div>



                </div>
            </div>
        )

    }

}

export default ProductList

// 
//



/* Software tab

    <div class="grid-container">
        <div class="grid-item">SmartScope Series</div>
        <div class="grid-item">Fusion Series</div>
        <div class="grid-item">FlexPoint Series</div>
        <div class="grid-item">SprintMVP Series</div>
        <div class="grid-item">SparkMVP Series</div>
    </div>
</div> */