import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import ProductList from './components/product_list';
import Footer from '../../components/footer';
import ProductBanner from "./components/product_banner"
import banner from './banner_img/banner_img.png'
import bannermobile from './banner_img/banner_mobile.png'
// import ProductCarousel from './product_carousel'

class Products extends Component {
  render(){
  return (
    <div className="home">
      <Header></Header>
      <Navibar></Navibar>
      {ProductBanner("Metrology Systems", banner, bannermobile)}
      <ProductList></ProductList>
      <Footer></Footer>


    </div>
  );
  }
}

export default Products;