import React, { Component } from 'react';
import plasticimg from './img/plastic.png'
import engineeringimg from './img/engineering.png'
import medicalimg from './img/medical.png'
import { Link, withRouter } from "react-router-dom"

export default withRouter(class Map extends Component {

    render() {
        return (
            <div>
                <div className="solution-body" id="solution-n">

                    <div className="solution-content">

                        <div >
                            <div className="solution-container">
                                <img className="solution-img" src={plasticimg} alt="plastic"></img>
                                <div className="solution-img-text">
                                    <h3 >Plastic Molding</h3>
                                    <p className="solution-description">With the injection molding process posing sheer speed, it's a real challenge to effectively manage quality control in plastics manufacturing. Many plastics will also feature complex parts and will possess a wide range of colors, sizes and textures, meaning manufacturers are challenged with checking statistics effectively without compromising on quality or production schedules.</p>
                                    <Link as={Link} to="/solutions/plastic"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>

                            </div>
                            <div className="solution-container">
                                <div className="solution-img-text">
                                    <h3>Precision Tooling</h3>
                                    <p className="solution-description">Our industry-leading multi-sensor metrology equipment not only allows for thorough, fast, and accurate measurement of your engineering parts, but also helps you to analyze your existing processes, enabling you to identify any outstanding inclusions or flaws and gain insight as to how your production line is performing- can it be improved?</p>
                                    <Link as={Link} to="/solutions/tooling"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>

                                <img className="solution-img" src={engineeringimg} alt="engineering"></img>
                            </div>
                            <div className="solution-container">
                                <img className="solution-img" src={medicalimg} alt="medical"></img>
                                <div className="solution-img-text">
                                    <h3>Medical Development</h3>
                                    <p className="solution-description">Medical devices are in many cases designed to be integrated into the human body, thus component accuracy is imperative. If component dimensions, for example a hip joint replacement, are inaccurate not only is money wasted during production of the part, and the patient's condition prolonged, but also without the correct equipment to identify the production line problem then this error is likely to repeat itself in the future.</p>
                                    <Link as={Link} to="/solutions/medical"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="solution-body" id="solution-m">

                    <div className="solution-content">

                        <div >
                            <div className="solution-container">

                                <div className="solution-img-text">
                                    <h3 >Plastic Molding</h3>
                                    <img className="solution-img" src={plasticimg} alt="plastic"></img>
                                    <p className="solution-description">With the injection molding process posing sheer speed, it's a real challenge to effectively manage quality control in plastics manufacturing. Many plastics will also feature complex parts and will possess a wide range of colors, sizes and textures, meaning manufacturers are challenged with checking statistics effectively without compromising on quality or production schedules.</p>
                                    <Link as={Link} to="/solutions/plastic"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>

                            </div>
                            <div className="solution-container">
                                <div className="solution-img-text">
                                    <h3>Precision Tooling</h3>
                                    <img className="solution-img" src={engineeringimg} alt="engineering"></img>
                                    <p className="solution-description">Our industry-leading multi-sensor metrology equipment not only allows for thorough, fast, and accurate measurement of your engineering parts, but also helps you to analyze your existing processes, enabling you to identify any outstanding inclusions or flaws and gain insight as to how your production line is performing- can it be improved?</p>
                                    <Link as={Link} to="/solutions/tooling"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>


                            </div>
                            <div className="solution-container">

                                <div className="solution-img-text">
                                    <h3>Medical Development</h3>
                                    <img className="solution-img" src={medicalimg} alt="medical"></img>
                                    <p className="solution-description">Medical devices are in many cases designed to be integrated into the human body, thus component accuracy is imperative. If component dimensions, for example a hip joint replacement, are inaccurate not only is money wasted during production of the part, and the patient's condition prolonged, but also without the correct equipment to identify the production line problem then this error is likely to repeat itself in the future.</p>
                                    <Link as={Link} to="/solutions/medical"><p className="solution-description" id="learn-more">{"Learn more >"}</p></Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
})
