import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductBanner from "../products/components/product_banner"
import banner from './img/error_banner.png'
import bannermobile from './img/error_banner_mobile.png'
import { withRouter } from "react-router-dom"

export default withRouter(class error extends Component {

    render() {

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner("Page Not Found", banner, bannermobile)}
                <div className="service_details_text_container" style={{textAlign:"center"}}><h3 className="service_details_text"><b>The Page You Are Looking For Cannot Be Found</b></h3></div>
                <Footer></Footer>
            </div >

        )
    }
})
