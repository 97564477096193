import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductBanner from "../products/components/product_banner"
import banner from './img/product_banner.png'
import bannermobile from './img/product_banner_mobile.png'


export default class contactus extends Component {

    render() {
        var current_url_tail = this.props.match.url.slice(10)
        var title = current_url_tail.split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" & ")
        console.log(title)

        const paragraph_generation = (text_list) => { return (<div className="service_details_text_container">{text_list.map((paragraph) => { 
            if (paragraph[0] ==="$") {
                return <p  className="service_details_text"><b>{paragraph.slice(1)}</b></p>
            }
            else {
                return <p className="service_details_text">{paragraph}</p>
            }
        })}</div>)}
        
        function ProductDetailsBody(name){
            if(name === "training"){

                title = current_url_tail.split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")
                console.log(title)
                return <div>
                    {paragraph_generation(["At Vision Measuring Oceania, we are committed to offering unmatched customer support and personal service for your needs. We believe that proper training is important in getting the most out of our measurement machines. All our representatives have been through rigorous training on the details of our measurement systems, and are a great first contact for training.", "Formal training classes are available at your location offering you the best experience to learn, on the system you are planning to operate. Courses combine lecture and hands-on experience starting with an introduction to the system.", "$Contact us and book a session with us today!"])}
                </div>
            }
            else if (name === "parts+upgrades"){
                title = current_url_tail.split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" & ")
                console.log(title)

                return <div>
                    {paragraph_generation(["At Vision Measuring Oceania we offer a full range of field installed upgrades and factory refurbishments to extend the life of your system. Keep your QVI measuring system up to date with a system upgrade.","$Field Upgrades","Our technicians can install a range of system upgrades to enhance system performance and reduce downtime.", "$Factory Upgrades:", "Vision Measuring Oceania offers factory rebuilds to upgrade older systems to enhance their performance.", "$Replacement Parts:",  "We maintain a parts inventory for current and discontinued systems, so repairs can hopefully be done quickly and completely. Please contact us for availability and pricing.", "$Software Upgrades:" ,"Update metrology and accessory software to the latest version for enhanced capability. What is the latest software for my machine?", "$System Upgrades:", "Call us for information on Windows® operating system upgrades."])}
                </div>
            }
            else if (name ==="field+service"){
                title = current_url_tail.split("+").map((word) => word[0].toUpperCase() + word.slice(1)).join(" ")
                console.log(title)
                return <div>
                    {paragraph_generation(["Vision Measuring Oceania is the official technical support and service team for QVI, RAM, CCP and OGP in the Oceanic region, encompassing Australia and New Zealand. Currently, we are based in Melbourne, Victoria. Our goal is to keep your systems operating in peak condition.", "We offer a range of field services from routine maintenance and calibration, to diagnosis and repair.", "$Preventive Maintenance:", "Schedule a service call for preventive maintenance, routine service, or repairs.", "$Calibration:", "Our calibration services use artifacts of appropriate size, density, and uncertainty for the full range of OGP, RAM, and CCP measuring systems."])}
                </div>
            }
            else{
                title = "Error"
                console.log(title)
                return <p></p>
            }
        }

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner(title, banner, bannermobile)}
                {ProductDetailsBody(current_url_tail)}
                <Footer></Footer>
            </div >

        )
    }
}
