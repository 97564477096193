import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Tab from './software_accessory_body';
import Footer from '../../components/footer';
import ProductBanner from "../products/components/product_banner"
// import banner from './img/contactus.png'
// import bannermobile from './img/contactus-mobile.png'

export default class contactus extends Component {

    render() {

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner("Software and Accessories")}
                <Tab></Tab>
                <Footer></Footer>
            </div >

        )
    }
}
