import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductBanner from "../products/components/product_banner"
import banner from './img/product_banner.png'
import bannermobile from './img/product_banner_mobile.png'
import Servicesbody from './services_body'


export default class contactus extends Component {

    render() {

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner("Service & Support", banner, bannermobile)}
                <Servicesbody></Servicesbody>
                <Footer></Footer>
            </div >

        )
    }
}
