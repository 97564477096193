import React, { Component } from 'react'
import { Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom"
// import nav_logo from "./logo_navibar.png"
// import nav_logo from "./Logo-01.svg"
// import nav_logo from '../img/Logo/logo-01.png'
// #323335
class navibar extends Component {

    render() {

        return (


            <Navbar collapseOnSelect className="light-dark" variant="dark" expand="md">
                <Navbar.Toggle />
                {/* <div className="nav_link_logo_container">
                            <Nav.Link bsPrefix="nav_link_logo" href="#home"><img className="navlogo" src={nav_logo} alt="logo" /></Nav.Link>
                        </div> */}

                <Navbar.Collapse className="navbar_collapse" style={{ "flexGrow": 0, "margin": "auto"}}>
                    <Nav className="navbar_items_container">

                        {/* <div className="nav_link_logo_container">
                            <Nav.Link bsPrefix="nav_link_logo" href=" "><img className="navlogo" src={nav_logo} alt="logo" /></Nav.Link>
                        </div> */}
                        <div className="navbaritems">
                            <Nav.Link className="navbar-white" as={Link} to="/solutions">Solutions</Nav.Link>
                        </div>
                        <div className="navbaritems">
                            <Nav.Link className="navbar-white" as={Link} to="/metrology+systems">Metrology Systems</Nav.Link>
                        </div>
                        <div className="navbaritems">
                            <Nav.Link className="navbar-white" as={Link} to="/software+accessories">Software & Accessories</Nav.Link>
                        </div>
                        <div className="navbaritems">
                            <Nav.Link className="navbar-white" as={Link} to="/services">Services & Support</Nav.Link>
                        </div>

                        <div className="navbaritems">
                            <Nav.Link className="navbar-white" as={Link} to="/contactus">Contact Us</Nav.Link>
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

        )
    }
}



export default navibar