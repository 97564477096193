import React, { Component } from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import IndustrySolution from './solution_types';
import ProductBanner from "../products/components/product_banner"
import banner from './img/banner-img.png'
import bannermobile from './img/banner-img-mobile.png'

export default class contactus extends Component {
    
    render() {

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                {ProductBanner("Industry Solutions",banner,bannermobile)}
                <IndustrySolution></IndustrySolution>
                <Footer></Footer>
            </div >

        )
    }
}
