import React, { Component } from 'react'
import Softwarejson from "./software_item.json"
import { Link, withRouter } from "react-router-dom"



export default withRouter(class ProductList extends Component{
    render() {
        const current_url_tail = this.props.match.url
        function softwaretabcontent(software_json) {

            var headers = Object.keys(software_json)

            //create items with image and name
            const grid = (headers, software_json) =>
                <div style={{textAlign:"center"}}>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/metrology+software"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/metrology/zone3.png"} alt="zone3"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>Metrology Software</b></div>
                            <p className="prod_text">Metrology software is the way users interact with OGP measurement systems. Controlling, monitoring, displaying, and reporting every aspect of the measuring process, providing consistent results 24/7</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/evolve+software+suite"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/evolve/evolvespc.png"} alt="evolvespc"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>Evolve Software Suite</b></div>
                            <p className="prod_text">The EVOLVE software suite optimizes design, production and inspection processes enabling manufacturers to shorten product design & development time, improve quality, and reduce costs through improved efficiency</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/fitting+software"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/fitting/smartfit.png"} alt="smartfit"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>Fitting Software</b></div>
                            <p className="prod_text">Fitting software are unparalleled precision when providing a true 3D comparison between a 3D CAD and the parts measered.</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/optical+comparator+software"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/optical/ecad.png"} alt="ecad"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>Optical Comparator Software</b></div>
                            <p className="prod_text">{"QVI®️ Certified Comparator Product's (CCP), optical comparators offer the industry’s best value and performance in the optical comparator domain"}</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/shapegrabber+software"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/shapegrabber/sgcapture.png"} alt="sgcapture"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>Shapegrabber Software</b></div>
                            <p className="prod_text">The data captured by ShapeGrabber 3D scanners is processed by leading 3D scanner software. These supported software products are proven to work with ShapeGrabber automated scanners to accurately analyze, graph and model all data points consistently and rapidly.</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                    <Link className="prod_item" as={Link} to={current_url_tail +"/user+interface+software"}>
                        <img className="prod_img" src={process.env.PUBLIC_URL + "/img/software_img/user/portal.png"} alt="portal"></img>
                        <div className="prod_card">
                            <div className="prod_header"><b>User Interface Software</b></div>
                            <p className="prod_text">Portal and LaunchPad enables administrator to manage the user domain and application program controls</p>
                            <p className="prod_learn"><b>{"Learn More >"}</b></p>
                        </div>
                    </Link>
                </div>

            var content = grid(headers, software_json)

            return content
        }


        return (
            softwaretabcontent(Softwarejson)

        )
    }
})

