import React from 'react'
import product_banner from "../banner_img/product_banner.png"
// import product_banner_tablet from "./banner_img/product_banner_tablet.webp"
import product_banner_mobile from "../banner_img/product_banner_mobile.png"

export default function ProductBanner(text, img = product_banner,mobile_img =product_banner_mobile, overlay_img) {
    var banner_text = text

        if (overlay_img){
            var overlay = <img className="prod_overlay_img" src={overlay_img} alt="overlay_img"></img>
        }
        else{
            overlay = <div></div>
        }

        banner_text = <div>
            <h3 id="product_banner_n" className="product_banner_header">{text.toUpperCase()}</h3>

        </div>
    

    var banner_text_mobile = <h3 id="product_banner_mobile" className="product_banner_header_mobile">{text.toUpperCase()}</h3>
    return (
        <div>
            <div id="product_banner_n" className="product_banner_container">
                <img id="product_banner_n" className="product_banner_image" src={img} alt="expo_img"></img>
                <div id="product_banner_n" className="product_banner_text_container">
                    {banner_text}
                </div>
                    {overlay}
            </div>
            {/* 
                <div id="product_banner_tablet" className="product_banner_container">
                    <img id="product_banner_table" className="product_banner_image" src={product_banner_tablet} alt="product_banner_img"></img>

                    <div id="product_banner_tablet" className="product_banner_text_container">
                        <h3 id="product_banner_tablet" className="product_banner_header_tablet">Upcoming Events</h3>
                        <h3 id="product_banner_tablet" className="product_banner_header_tablet">TBA</h3>
                    </div>


                </div> */}

            <div id="product_banner_mobile" className="product_banner_container">
                <img id="product_banner_mobile" className="product_banner_image" src={mobile_img} alt="product_banner_img"></img>

                <div id="product_banner_mobile" className="product_banner_text_container">
                    {banner_text_mobile}
                </div>


            </div>
        </div>
    )
}

