import React, { Component } from 'react'
import img from "../../../img/ExpoBanner.png"
import img_tablet from "../../../img/ExpoBannerTablet.png"
import img_mobile from "../../../img/ExpoBannerMobile.png"

class expo extends Component {
    render() {
        return (
            <div>
                <div id="expo_n" className="expo_container">
                    <img id="expo_n" className="expo_image" src={img} alt="expo_img"></img>

                    <div id="expo_n" className="expo_text_container">
                        <h3 id="expo_n" className="expo_header">Upcoming Events</h3>
                        <br />
                        {/* Name of event*/}
                        <h3 id="expo_n" className="expo_text">Australian Manufacturing Exhibition 2023</h3>

                        {/* date here*/}
                        <h3 id="expo_n" className="expo_text">9th - 12th May 2023</h3>

                        {/* booth here*/}
                        <h3 id="expo_n" className="expo_text"> </h3>
                    </div>


                </div>

                <div id="expo_tablet" className="expo_container">
                    <img id="expo_table" className="expo_image" src={img_tablet} alt="expo_img"></img>

                    <div id="expo_tablet" className="expo_text_container">
                        <h3 id="expo_tablet" className="expo_header_tablet">Upcoming Events</h3>
                        <br />
                        {/* Name of event*/}
                        <h3 id="expo_tablet" className="expo_header_tablet">Australian Manufacturing Exhibition 2023</h3>

                        {/* date here*/}
                        <h3 id="expo_tablet" className="expo_header_tablet">9th - 12th May 2023</h3>

                        {/* booth here*/}
                        <h3 id="expo_tablet" className="expo_header_tablet"> </h3>

                    </div>


                </div>

                <div id="expo_mobile" className="expo_container">
                    <img id="expo_mobile" className="expo_image" src={img_mobile} alt="expo_img"></img>

                    <div id="expo_mobile" className="expo_text_container">
                        <h3 id="expo_mobile" className="expo_header_mobile">Upcoming Events</h3>

                        <br />
                        {/* Name of event*/}
                        <h3 id="expo_mobile" className="expo_header_mobile">Australian Manufacturing Exhibition 2023</h3>

                        {/* booth here*/}
                        <h3 id="expo_mobile" className="expo_header_mobile">9th - 12th May 2023</h3>

                        {/* booth here*/}
                        <h3 id="expo_mobile" className="expo_header_mobile"> </h3>

                    </div>


                </div>
            </div>
        )
    }
}

export default expo