import React from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductBanner from "../products/components/product_banner"
import banner from './img/banner-img.png'
import bannermobile from './img/banner-img-mobile.png'
import { Link, Route, Redirect } from "react-router-dom"

export default function contactus(match) {
    const url_tail = match.location.pathname.slice(11)

    var solution_template = (title, Textbody) =>
        <div className="home" >
            <Header></Header>
            <Navibar></Navibar>
            {ProductBanner(title, banner, bannermobile)}
            {Textbody}
            <Footer></Footer>
        </div >

    var text

    if (url_tail === "plastic") {
        text = <div className="solution-container">
            <div className="solution-img-text" id="solution-details">
                <p className="solution-description">
                    With the injection molding process posing sheer speed, it's a real challenge to effectively manage quality control in plastics manufacturing. Many plastics will also feature complex parts and will possess a wide range of colors, sizes and textures, meaning manufacturers are challenged with checking statistics effectively without compromising on quality or production schedules.
                    <br /><br />
                    Automated, non contact video based measuring systems from OGP allows for reliable, fast and effective measurement of both simple plastic parts, and more complex pieces without effecting production.</p>
                <Link as={Link} to="/contactus"><p className="solution-description" id="learn-more">Contact us for more information</p></Link>
            </div>
        </div>
        return (
            solution_template("Plastic Molding", text)
        )
    }
    else if (url_tail === "tooling") {
        text = <div className="solution-container">
            <div className="solution-img-text" id="solution-details">
                <p className="solution-description">
                    Specialized machinery for a wide range of industries demands precision engineering in order to operate and perform with optimal results. Therefore engineering manufacturers strive for utmost precision in their work- making tooling accuracy a priority. Most importantly, engineering companies need to be able to demonstrate that their components adhere to strict, micron-tight tolerances through a verification process that can analyze the acquisition of component surface data down to the last micron. This verification process can only be carried out with world-leading measurement technology to enable engineering companies to guarantee their customers the highest standards of quality.
                    <br /><br />
                    OGP's industry-leading multi-sensor metrology equipment not only allows for thorough, fast and accurate measurement of your engineering parts but also helps you to analyze your existing processes, enabling you to identify any outstanding inclusions or flaws and gain insight as to how your production line is performing- can it be improved?
                    <br /><br />
                    With our advanced multi-sensor measurement solutions you will boost measurement throughput, minimize lead times and gain a greater comprehensive appreciation of the quality of your own products.
                    <br /><br />
                    OGP machine features offer optimum performance in a variety of facility environments, delivering consistently accurate and fast measurement data collection. And because multi-sensor technology is at the center of our metrology solutions many of our machines are able to integrate a number of different sensors such as touch probe, laser, low forced feather probe or nanometre white light sensor to deliver maximum flexibility on selected single machines and can be further enhanced with the option of implementing rotary devices working singularly or in tandem to provide up to five axes of movement.
                    <br /><br />
                    OGP also provides a wide variety of advanced system software that can offer you extremely powerful yet easy and intuitive use. With the ability to conduct fully automated measurement routines that can be programmed in minutes, while data outputs can be customized to meet personal reporting formats, or used by analysis packages to compute 3D surface profiles, part-to-CAD comparisons and SPC for reverse engineering, your engineering precision will be of the highest standards than ever before.</p>
                <Link as={Link} to="/contactus"><p className="solution-description" id="learn-more">Contact us for more information</p></Link>
            </div>
        </div>
        return (
            solution_template("Precision Tooling", text)
        )
    }
    else if (url_tail === "medical") {
        text = <div className="solution-container">
            <div className="solution-img-text" id="solution-details">
                <p className="solution-description">
                    Because of the nature and intended use of medical devices, manufacturing accuracy is critically important.
                    <br /><br />
                    Medical devices are often manufactured to extremely strict tolerances – therefore, measurement systems that characterize these medical devices must be capable of high precision, often to the submicron level.
                    <br /><br />
                    OGP offers tailor-made solutions for medical device manufacturing, by integrating a variety of sensors that can measure even the smallest, most complex components. OGP solutions provide the tools to measure complex parts quickly and accurately, with systems and software which are unparalleled.
                    <br /><br />
                    OGP metrology systems are ideal for ensuring high standards of quality are met throughout your manufacturing process, enabling you to feel confident in your products.</p>
                <Link as={Link} to="/contactus"><p className="solution-description" id="learn-more">Contact us for more information</p></Link>
            </div>
        </div>
        return (
            solution_template("Medical Development", text)
        )
    }
    else {
        return <Route render={() => <Redirect to={"/error"} />} />
    }
    // text = <div className="solution-container">
    //     <div className="solution-img-text" id="solution-details">
    //         <p className="solution-description"> Page Not Found</p>

    //     </div>
    // </div>
    // return (
    //     solution_template("Page not found", text)
    // )


}
