import React from 'react';
import { withRouter, Link,Route, Redirect  } from "react-router-dom"



export default withRouter(function productitems(match) {


    function product_tab(evt, familyName) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent-product");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(familyName).style.display = "block";
        evt.currentTarget.className += " active";

        document.getElementById("selectBox").value = evt.currentTarget.innerHTML
    }

    function changeFunc() {
        var selectBox = document.getElementById("selectBox");
        var selectedValue = selectBox.options[selectBox.selectedIndex].value;
        
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent-product");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(selectedValue).style.display = "block";
        var tab = document.getElementById(selectedValue + " tab")
        tab.className += " active"
       }

    var all_products = require('./product_list.json')

    const current_url_tail = match.location.pathname.slice(19)
    try {
        if ((current_url_tail.includes("/") && all_products[current_url_tail.split("/")[0]] !== undefined) || all_products[current_url_tail] !== undefined) {

            let product_names = ""
            if (current_url_tail.includes("/") && all_products[current_url_tail.split("/")[0]] !== undefined) {
                product_names = Object.keys(all_products[current_url_tail.split("/")[0]][current_url_tail.split("/")[1]])
                all_products = all_products[current_url_tail.split("/")[0]][current_url_tail.split("/")[1]]
                var product_series = all_products
            }
            else {
                product_names = Object.keys(all_products[current_url_tail])
                product_series = all_products[current_url_tail]
            }
            const product_names_tabs = product_names.slice(1)
            const first_item = product_names[0]

            // const content_template = (item_img, item_name, item_description) => <div>

            // </div>


            const table = (product_spec) => <div>
                <div className="tech-spec-word">Technical Specifications</div>
                {product_spec !== "" ? <table className="tech-spec">

                    {product_spec.map(column => column[1].includes("#") ?
                        <tr>
                            {<td className="table-head" style={{ "width": 100 / (column[1].split("#").length + 1) + "%" }}>{column[0]}</td>}
                            {<td className="table-head" style={{ "width": 100 / (column[1].split("#").length + 1) + "%" }}>{column[1].split("#").map(item => <p>{item}</p>)}</td>}
                        </tr> :

                        <tr>
                            {column.map(specs => <td className="table-head" style={{ "width": 100 / column.length + "%" }} >{specs}</td>)}
                        </tr>

                    )}
                </table> : <div></div>}

                <p className="table-subtext"><Link className="text-link" as={Link} to="/contactus">Contact us for more information</Link></p>


            </div>

            const tab_template = (product_names) =>

                <div>

                    <div className="product_combobox">
                        <select id="selectBox" className="form-control" onChange={() => changeFunc()}>
                            <option value={product_series[first_item].name} className="tablinks">{product_series[first_item].name}</option>)
                            {product_names_tabs.map(product => <option value={product_series[product].name} className="tablinks">{product_series[product].name}</option>)}

                        </select>
                    </div>

                    <div className="tab">
                        {<button className="tablinks active" id={product_series[first_item].name + " tab"} style={{ "width": (100 / product_names.length) + "%" }} onClick={(e) => product_tab(e, product_series[first_item].name)}>{product_series[first_item].name}</button>}
                        {product_names_tabs.map(product => <button className="tablinks" id={product_series[product].name + " tab"} style={{ "width": 100 / product_names.length + "%" }} onClick={(e) => product_tab(e, product_series[product].name)}>{product_series[product].name}</button>)}
                    </div>

                    <div className="producttabcontentholder">

                        <div id={product_series[first_item].name} className="tabcontent-product" style={{ display: "block" }}>
                            <div className="prod-name">{product_series[first_item].full_name}</div>
                            <div class="grid-test" id={product_series[first_item].name}>
                                <div class="grid-item-img">
                                    <img className="grid-img-test" src={process.env.PUBLIC_URL + product_series[first_item].img} alt={product_series[first_item].name}></img>
                                </div>
                                <div class="grid-item-des">{product_series[first_item].description.split("#").map(paragraphs => <p>{paragraphs}<br /><br /></p>)}</div>
                            </div>
                            <div class="grid-test" style={{ "grid-template-columns": "100%" }} id={product_series[first_item].name}>
                                <div class="grid-item-test">{table(product_series[first_item].spec)}</div>
                            </div>
                        </div>

                        {product_names_tabs.map(product =>

                            <div id={product_series[product].name} className="tabcontent-product" style={{ display: "none" }}>
                                <div className="prod-name">{product_series[product].full_name}</div>
                                <div class="grid-test" id={product_series[product].name}>
                                    <div class="grid-item-img">
                                        <img className="grid-img-test" src={process.env.PUBLIC_URL + product_series[product].img} alt={product_series[product].name}></img>
                                    </div>
                                    <div class="grid-item-des">{product_series[product].description.split("#").map(paragraphs => <p>{paragraphs}<br /><br /></p>)}</div>
                                </div>
                                <div class="grid-test" style={{ "grid-template-columns": "100%" }} id={product_series[product].name}>
                                    <div class="grid-item">{table(product_series[product].spec)}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            return tab_template(product_names, all_products)
        }
        else {
            return <Route render={() => <Redirect to={"/error"} />} />
        }
    }
    catch (err) {
        return <Route render={() => <Redirect to={"/error"} />} />

    }

})
