import React, { Component } from 'react'
import Softwaretab from "./software/software_tab"
import Accessorytab from "./accessories/accessory_tab"



export default class ProductList extends Component {
    render() {
        function product_tab(evt, familyName) {
            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(familyName).style.display = "block";
            evt.currentTarget.className += " active";
                    document.getElementById("selectBox").value = evt.currentTarget.innerHTML
        }

        function changeFunc() {
            var selectBox = document.getElementById("selectBox");
            var selectedValue = selectBox.options[selectBox.selectedIndex].value;

            var i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(selectedValue).style.display = "block";
            var tab = document.getElementById(selectedValue + " tab")
            
            tab.className += " active"
        }

        return (
            <div>
                <div className="product_combobox">
                    <select id="selectBox" className="form-control" onChange={() => changeFunc()}>

                        <option value="Accessories" className="tablinks">Accessories</option>)
                        <option value="Software" className="tablinks">Software</option>)

                    </select>
                </div>
                <div className="productline">
                    <div className="tab_accessories_software">
                        <button className="tablinks active" id="Accessories tab" onClick={(e) => product_tab(e, "Accessories")}>Accessories</button>
                        <button className="tablinks" id="Software tab" onClick={(e) => product_tab(e, "Software")}>Software</button>


                    </div>

                    {/* Multisensory tab */}

                    <div className="producttabcontentholder">
                        <div id="Accessories" className="tabcontent" style={{ display: "block" }}>
                            <Accessorytab></Accessorytab>
                        </div>


                        <div id="Software" className="tabcontent">
                            <Softwaretab></Softwaretab>
                        </div>


                    </div>
                </div>
            </div>

        )

    }

}
