import React from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import { Link, withRouter} from "react-router-dom"
import ProductBanner from "./components/product_banner"
import banner from './banner_img/banner_img.png'
import bannermobile from './banner_img/banner_mobile.png'


export default withRouter(function productitems(match) {
    const all_products = require('./smartscope_series.json')
    var product_list = all_products
    const current_url_tail = match.location.pathname.slice(19)

    var product_details = <div></div>
    if (product_list[current_url_tail] !== undefined) {
        product_details = product_list[current_url_tail]
        var system_products = Object.keys(product_details)

        product_details = system_products.map(system => {

            var family = "/metrology+systems/"+ match.location.pathname.slice(19) +"/" + product_details[system].family;
            var name = product_details[system].name;
            var img = product_details[system].img;
            var des = product_details[system].description
            return [family,name,img, des]

        }
        ).map(system =>
            <Link className="sys_prod_item" as={Link} to={system[0]}>
                <img className="sys_prod_img" src={process.env.PUBLIC_URL + system[2]} alt={system[1]}></img>
                <div className="sys_prod_card">
                    <div className="sys_prod_header"><b>{system[1]}</b></div>
                    <p className="sys_prod_text">{system[3]}</p>
                </div>
            </Link>

            
        // <Link className="product_list_img_captions" as={Link} to={system[0]}>
        //     <div class="grid-item"><img className="grid-img" src={process.env.PUBLIC_URL + system[2]} alt={system[1]}></img><figcaption id="product-series-text">{system[1]}</figcaption></div>
        // </Link>
        )
        
        var grid_details = <div style={{textAlign:"center"}}>{product_details}</div>;
    }
    else {
        grid_details = <div>
            <p></p>
        </div>
    }
    
    return (
        <div className="home">
            <Header></Header>
            <Navibar></Navibar>
            {ProductBanner(match.location.pathname.slice(19).charAt(0).toUpperCase() + match.location.pathname.slice(19).slice(1) + " Series", banner,bannermobile)}


            {grid_details}
            <Footer></Footer>
        </div>
    );
})
