import React, { Component } from 'react';
import support from "./img/support.png"
import parts from "./img/parts.png"
import software_renewal from "./img/software_renewal.png"
import training from "./img/training.png"
import calibration from "./img/calibration.png"
import { Link, withRouter } from "react-router-dom"

export default withRouter (class contactus extends Component {

    render() {

        console.log(this.props.match.url)
        var current_url_tail = this.props.match.url
        return (
            <div className="service_body">
                <div>
                    <div className="service_card" >
                        <img className="service_card_img" src={support} alt="support" />
                        <div className="service_card_text">
                            <h3 className="service_card_header">Technical Support</h3>
                            <p>At Vision Measuring Oceania we are always delighted to assist you with any technical difficulties and questions you may have. Our skilled staff can often diagnose problems, and tell you how to fix them, within the context of a phone call.</p>
                            <br/>
                            <Link as={Link} to="/contactus">{"Contact Us >"}</Link>
                        </div>
                    </div>

                    <div className="service_card">
                        <img className="service_card_img" src={parts} alt="parts" />
                        <div className="service_card_text">
                            <h3 className="service_card_header">{"Parts & Upgrades"}</h3>
                            <p>We offer a full range of field installed upgrades and factory refurbishments to extend the life of your system. Keep your QVI measuring system up to date with a system upgrade.</p>
                            <br/>
                            <Link as={Link} to={current_url_tail + "/parts+upgrades"}>{"Learn More >"}</Link>
                        </div>
                    </div>
                    <div className="service_card">
                        <img className="service_card_img" src={software_renewal} alt="software_renewal" />
                        <div className="service_card_text">
                            <h3 className="service_card_header">Software Renewal</h3>
                            <p>Renew your software license – quick and easy!</p>
                            <p>Visit QVI's official website</p>
                            <br/>
                            <a href="https://www.qvii.com/service-support/software-license-renewal/">{"Visit QVI Software Renewal >"}</a>
                        </div>
                    </div>
                    <div className="service_card">
                        <img className="service_card_img" src={training} alt="training" />
                        <div className="service_card_text">
                            <h3 className="service_card_header">Training</h3>
                            <p>At Vision Measuring Oceania, we are committed to offering unmatched customer support and personal service. Training is important in getting the most out of our measurement machines.</p>
                            <br/>
                            <Link as={Link} to={current_url_tail + "/training"}>{"Learn More >"}</Link>
                        </div>
                    </div>
                    <div className="service_card">
                        <img className="service_card_img" src={calibration} alt="calibration" />
                        <div className="service_card_text">
                            <h3 className="service_card_header">Field Services</h3>
                            <p>We offer a range of field services for your convenience; from routine maintenance and calibration, to diagnosis and repair.</p>
                            <br/>
                            <Link as={Link} to={current_url_tail + "/field+service"}>{"Learn More >"}</Link>
                        </div>
                    </div>
                </div>
            </div >

        )
    }
})
