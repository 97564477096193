import React from 'react';
import Navibar from '../../components/navibar';
import Header from '../../components/header';
import Footer from '../../components/footer';
import ProductItems from './product_item';
import ProductBanner from "./components/product_banner"
import { Route, Redirect } from "react-router-dom"
import banner from './banner_img/banner_img.png'
import bannermobile from './banner_img/banner_mobile.png'

export default function productitems(match) {

    var banner_title
    var all_products = require('./product_list.json')

    const current_url_tail = match.location.pathname.slice(19)

    try {
        if ((current_url_tail.includes("/") && all_products[current_url_tail.split("/")[0]] !== undefined) || all_products[current_url_tail] !== undefined) {

            if (match.location.pathname.split("/").length >= 2) {
                banner_title = match.location.pathname.split("/").splice(2).map(word => word.split("-").join(" ")).join(" ")
                if (banner_title.includes("smartscope cnc")) {

                    banner_title = banner_title.split(" ")
                    banner_title.splice(1, 0, "Flash")

                    banner_title = banner_title.join(" ")
                }
                else if (banner_title.includes("sprintmvp")) {
                    banner_title = "QVI® SprintMVP"
                }
                else if (banner_title.includes("sparkmvp")) {
                    banner_title = "QVI® SparkMVP"
                }
                else if (banner_title.includes("flexpoint")) {
                    banner_title = "QVI® FlexPoint"
                }
                else if (banner_title.includes("fusion")) {
                    banner_title = "QVI® Fusion"
                }
                else if (banner_title.includes("snap")) {
                    banner_title = "QVI® SNAP"
                }
                else if (banner_title.includes("starlite")) {
                    banner_title = "QVI® StarLite"
                }
                else if (banner_title.includes("shapegrabber")) {
                    banner_title = "QVI® ShapeGrabber"
                }
                else if (banner_title.includes("cobra")) {
                    banner_title = "QVI® Cobra"
                }
                else if (banner_title.includes("opticscomparatorb")) {
                    banner_title = "QVI® Optics Comparators Benchtop"
                }                
                else if (banner_title.includes("opticscomparatorf")) {
                    banner_title = "QVI® Optics Comparators Floor"
                }
                banner_title += " Range"
            }
            else {
                banner_title = match.location.pathname.split("/").pop().split("-").join(" ") + " Range"
            }
        }
        else{
            return <Route render={() => <Redirect to={"/error"} />} />
        }
    }
    catch(e){
        banner_title = "Error"
    }

        return (
            <div className="home" >
                <Header></Header>
                <Navibar></Navibar>
                { ProductBanner(banner_title, banner, bannermobile)}
                <ProductItems></ProductItems>
                <Footer></Footer>
            </div >

        )
    }
